import React, { Component } from 'react'
import { Button, Offcanvas } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { getVisaDetails, PostEnquiry } from '../../Services/VisaService';
import { toast } from 'react-toastify';
import parse from 'html-react-parser';

class VisaDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      VisaDetailList: [],
      RequiredDocument: "",
      EmbassyDetails: "",
      Notes: "",
      countryName: '',
      minAmount: '',
      bannerImage: '',
      isSubmitted: false,
      isFormValid: false,
      isName: false,
      isMobile: false,
      isEmail: false,
      selectedId: 0,
      formData: {
        CustomerName: '',
        CustomerEmail: '',
        CustomerMobile: '',
        VisaId: 0
      },
      errors: {
        CustomerEmail: '',
        CustomerMobile: ''
      }
    };
  }

  componentDidMount() {
    const { id } = this.props;

    this.getVisaDetails({ CountryId: id });
    window.scrollTo(0, 0);
  }

  getVisaDetails(data) {
    getVisaDetails(data).then((response) => {
      console.log(response)
      if (response.data.status) {
        this.setState({
          VisaDetailList: response.data.visaList,
          minAmount: response.data.minAmount,
          countryName: response.data.countryName,
          bannerImage: response.data.bannerImage,
          IsLoaded: true
        }, () => { console.log(this.state) });
      }
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    const errors = this.validateField(name, value);

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        ...errors
      }
    }));
  };

  validateField(name, value) {
    let errors = {};

    switch (name) {
      case 'CustomerEmail':
        this.setState({ isEmail: false });
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!value || value.trim() === '') {
          errors.CustomerEmail = 'Email is required';
        } else if (!emailRegex.test(value)) {
          errors.CustomerEmail = 'Email is not valid';
        } else {
          this.setState({ isEmail: true });
          errors.CustomerEmail = '';
        }
        break;

      case 'CustomerMobile':
        this.setState({ isMobile: false });
        const mobileRegex = /^[0-9]{10}$/; // Adjust the regex as per your requirements
        if (!value || value.trim() === '') {
          errors.CustomerMobile = 'Mobile number is required';
        } else if (!mobileRegex.test(value)) {
          errors.CustomerMobile = 'Mobile number must be 10 digits';
        } else {
          this.setState({ isMobile: true });
          errors.CustomerMobile = '';
        }
        break;

      default:
        break;
    }

    return errors;
  }


  clearFormData = () => {
    this.setState({
      formData: {
        CustomerName: '',
        CustomerEmail: '',
        CustomerMobile: ''
      },
      errors: {
        CustomerEmail: '',
        CustomerMobile: ''
      },
      isSubmitted: false // Reset submitted flag
    });
  };

  handleDropDownChange = (event) => {

    this.setState({
      selectedId: event.target.value
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ isSubmitted: true });
    const { formData, errors, isMobile, isEmail } = this.state;
    formData.VisaId = this.state.selectedId;

    if (formData.CustomerName == '' || (!isMobile || formData.CustomerMobile == '') || (!isEmail || formData.CustomerEmail == '') || (formData.VisaId == 0)) {
      this.setState({ isFormValid: false });
      return;
    }
    else {
      this.setState({ isFormValid: true });
    }

    PostEnquiry(formData).then((response) => {
      if (response.data.status) {

        toast.success('Enquiry Submitted Successfully...', {
          position: 'bottom-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined, // or any other configuration you want
          theme: 'colored'
        });

        this.clearFormData();
      }
      else {
        toast.error('Something went wrong...', {
          position: 'bottom-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined, // or any other configuration you want
          theme: 'colored'
        });
      }
    });
  };

  handleDocClose = () => this.setState({ show: false });
  handleDocShow = (requiredDocuments, embassyDetails, notes) => {
    this.setState({
      show: true,
      RequiredDocument: requiredDocuments,
      EmbassyDetails: embassyDetails,
      Notes: notes
    });
  }
  render() {

    const { VisaDetailList, countryName, minAmount, isFormValid, isSubmitted, errors, RequiredDocument, EmbassyDetails, Notes } = this.state;
    const { CustomerName, CustomerEmail, CustomerMobile } = this.state.formData;

    return (
      <div>
        <section
          className="visa-banner"
          style={{
            backgroundImage: `url(${this.state.bannerImage || '/assets/img/visa_default.jpg'})`,
          }}
        >

          <div className="container">
            <h2 className="text-white">{countryName} Visa</h2>
            {minAmount > 0 && <div className="visa-price-header">
              <p>Price starting at <span>INR {minAmount}*</span></p>
            </div>
            }
          </div>
        </section>

        <section>
          <div className="container">

            <div className="row">
              <div className="col-lg-8 col-md-8">
                <div className="d-flex flex-row align-items-center">
                  <div>
                    <h4>Types of {countryName} Visa</h4>
                  </div>

                </div>


                <div className="visa-type-block pt-2">
                  <div className="row">
                    {VisaDetailList.length > 0 && VisaDetailList.map((item, index) => (
                      <div className="col-lg-6 col-md-6">

                        <div className="card-style">
                          <div className="card  left">
                            <div className="card-head">{item.name}</div>

                            <ul className="akb-mrg-none visa-type left">
                              <li>
                                Processing time:
                                <span>Upto {item.processingTime} days</span>
                              </li>
                              <li>
                                Stay period:
                                <span>{item.stayPeriod} days</span>
                              </li>
                              <li>
                                Validity:
                                <span>{item.validity} days</span>
                              </li>
                              <li>
                                Entry:
                                <span>{item.entry}</span>
                              </li>
                              <li>
                                Fees:
                                {item.fee > 0 ? <span className="visa-price">INR {item.fee}/-</span>
                                  : <span className="visa-price">Contact Us</span>
                                }
                              </li>
                            </ul>
                            <button className="btn btn-doc" onClick={() => { this.handleDocShow(item.requiredDocuments, item.embassyDetails, item.notes) }}>Required Documents</button>
                          </div>
                        </div>
                      </div>
                    ))}

                  </div>
                </div>
                <Offcanvas show={this.state.show} onHide={this.handleDocClose} placement="end" className="large-offcanvas visa-canvas">
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Required Documents for {countryName} Visa</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <div className="card p-2">

                      <h4>Required Documents</h4>
                      <hr style={{ margin: '5px 0' }} />
                      {parse(RequiredDocument)}

                      <h4>Embassy Details</h4>
                      <hr style={{ margin: '5px 0' }} />
                      {parse(EmbassyDetails)}

                      <h4>Notes</h4>
                      <hr style={{ margin: '5px 0' }} />
                      {parse(Notes)}
                    </div>


                  </Offcanvas.Body>
                </Offcanvas>
              </div>

              <div className="col-lg-4 col-md-4">
                <div className="sidebar-enquiry">
                  <aside className="widget-area">
                    <div className="sidebar__form sidebar__single">
                      <div className="package_price_block d-flex flex-row align-items-center">
                        <h6>Fill the Form to Submit an Enquiry</h6>

                      </div>
                      <div className="sidebar__search">
                        <input
                          type="text"
                          name="CustomerName"
                          placeholder="Full Name*"
                          value={CustomerName}
                          onChange={this.handleChange}
                        />
                        <input
                          type="text"
                          name="CustomerEmail"
                          placeholder="Email ID*"
                          value={CustomerEmail}
                          onChange={this.handleChange}
                        />
                        {isSubmitted && errors.CustomerEmail && <span className="error">{errors.CustomerEmail}</span>}
                        <input
                          type="text"
                          name="CustomerMobile"
                          placeholder="Mobile No.*"
                          value={CustomerMobile}
                          onChange={this.handleChange}
                        />
                        {isSubmitted && errors.CustomerMobile && <span className="error">{errors.CustomerMobile}</span>}
                        <select onChange={this.handleDropDownChange}>
                          <option selected value={0}>Select One Visa Option</option>
                          {VisaDetailList.length > 0 && VisaDetailList.map((visa, index) => (
                            <option key={index} value={visa.visaId}>
                              {visa.name}
                            </option>
                          ))}

                        </select>
                        <button className="btn btn-primary w-100" onClick={this.handleSubmit}>SUBMIT</button>
                        {isSubmitted && !isFormValid && <span className="error">Fill All Fields</span>}
                      </div>
                    </div>
                    <div className="help-area">
                      <h4>Need Help?</h4>
                      <p>
                        Our Visa expert will be happy to help you resolve your queries.
                      </p>
                      <div className="d-flex flex-row align-items-center">
                        <div className="mr-10">
                          <svg
                            height="30px"
                            width="30px"
                            version="1.1"
                            id="_x32_"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 512 512"
                            xmlSpace="preserve"
                            fill="#000000"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <style
                                type="text/css"
                                dangerouslySetInnerHTML={{ __html: " .st0{fill:#ed1c24;} " }}
                              />{" "}
                              <g>
                                {" "}
                                <path
                                  className="st0"
                                  d="M337.168,289.578c-5.129-5.133-13.457-5.133-18.598,0l-16.031,16.038c-4.68,4.68-12.122,5.149-17.352,1.102 c0,0-15.332-10.109-40.402-35.179c-25.07-25.07-35.175-40.414-35.175-40.414c-4.055-5.226-3.578-12.656,1.102-17.343l16.031-16.031 c5.141-5.134,5.141-13.462,0-18.594l-35.34-35.343c-5.133-5.133-13.453-5.133-18.594,0c-0.122,0.125-1.906,1.906-21.309,21.32 c-22.602,22.594,7.293,91.82,57.574,142.118c50.289,50.281,119.527,80.164,142.121,57.578 c19.394-19.406,21.184-21.203,21.305-21.32c5.141-5.133,5.141-13.461,0-18.586L337.168,289.578z"
                                />{" "}
                                <path
                                  className="st0"
                                  d="M256,0C114.614,0,0,114.617,0,256s114.614,256,256,256s256-114.617,256-256S397.386,0,256,0z M256,472 c-119.102,0-216-96.898-216-216S136.898,40,256,40s216,96.898,216,216S375.102,472,256,472z"
                                />{" "}
                              </g>{" "}
                            </g>
                          </svg>
                        </div>
                        <div>
                          <h4 className="no-margin">0495 2389900</h4>
                        </div>
                      </div>
                    </div>

                  </aside>
                </div>

              </div>




            </div>

          </div>
        </section>
      </div>
    )
  }
}

// Create a functional component to wrap with useParams
const UmrahItineraryWithParams = () => {
  const { id } = useParams();
  return <VisaDetails id={id} />;
};

export default UmrahItineraryWithParams;
