import React, { Component } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { GetHajjPackageDetailsById, PostHajjPackageEnquiry } from "../../Services/HajjPackageServices";
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

class UmrahItinerary extends Component {

  constructor(props) {
    super(props);
    this.state = {
      HajjPackageDetails: {},
      packageId:0,
      deptId : 0,
      IsLoaded: false,
      isSubmitted: false,
      isFormValid: false,
      isName: false,
      isMobile: false,
      isEmail: false,
      formData: {
        CustomerName: '',
        CustomerEmail: '',
        CustomerMobile: '',
        PackageId: 0,
        DeptId:0
      },
      errors: {
        CustomerEmail: '',
        CustomerMobile: ''
      }
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { id, deptId } = this.props;
    this.setState({
      packageId : id,
      deptId : deptId
    })
    this.getHajjPackageDetails({ packageId: id, deptId: deptId });
  }

  getHajjPackageDetails(data) {
    console.log(data);
    GetHajjPackageDetailsById(data).then((response) => {
      if (response.data.packageName != null || response.data.packageName != "") {
        this.setState({
          HajjPackageDetails: response.data,
          IsLoaded: true
        });
      }
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    const errors = this.validateField(name, value);

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        ...errors
      }
    }));
  };

  areAllFieldsFilled() {
    const { formData } = this.state;
    for (let key in formData) {
      if (!formData[key] || formData[key].trim() === '') {
        return false;
      }
    }
    return true;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ isSubmitted: true });
    const { formData, errors, isMobile, isEmail } = this.state;

    if (formData.CustomerName == '' || (!isMobile || formData.CustomerMobile == '') || (!isEmail || formData.CustomerEmail == '')) {
      this.setState({ isFormValid: false });
      return;
    }
    else {
      this.setState({ isFormValid: true });
    }
    //PackageId added...
    formData.PackageId = this.state.HajjPackageDetails.packageId;
    formData.DeptId = this.state.deptId;

    PostHajjPackageEnquiry(formData).then((response) => {
      if (response.data.status) {

        toast.success('Enquiry Submitted Successfully...', {
          position: 'bottom-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined, // or any other configuration you want
          theme: 'colored'
        });

        this.clearFormData();
      }
      else {
        toast.error('Something went wrong...', {
          position: 'bottom-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined, // or any other configuration you want
          theme: 'colored'
        });
      }
    });
  };


  validateField(name, value) {
    let errors = {};

    switch (name) {
      case 'CustomerEmail':
        this.setState({ isEmail: false });
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!value || value.trim() === '') {
          errors.CustomerEmail = 'Email is required';
        } else if (!emailRegex.test(value)) {
          errors.CustomerEmail = 'Email is not valid';
        } else {
          this.setState({ isEmail: true });
          errors.CustomerEmail = '';
        }
        break;

      case 'CustomerMobile':
        this.setState({ isMobile: false });
        const mobileRegex = /^[0-9]{10}$/; // Adjust the regex as per your requirements
        if (!value || value.trim() === '') {
          errors.CustomerMobile = 'Mobile number is required';
        } else if (!mobileRegex.test(value)) {
          errors.CustomerMobile = 'Mobile number must be 10 digits';
        } else {
          this.setState({ isMobile: true });
          errors.CustomerMobile = '';
        }
        break;

      default:
        break;
    }

    return errors;
  }


  clearFormData = () => {
    this.setState({
      formData: {
        CustomerName: '',
        CustomerEmail: '',
        CustomerMobile: ''
      },
      errors: {
        CustomerEmail: '',
        CustomerMobile: ''
      },
      isSubmitted: false // Reset submitted flag
    });
  };

  render() {
    const { HajjPackageDetails, errors, isSubmitted, isFormValid } = this.state;
    const { CustomerName, CustomerEmail, CustomerMobile } = this.state.formData;
    return(
    (this.state.IsLoaded) ? (<div>
      <section className="blog-page section-space">
        <div className="container">
          <div className="row gutter-y-60">
            <div className="col-lg-8">
              <div className="blog-details">
                <h1 className="blog-card__title1">
                  {HajjPackageDetails.packageName}
                </h1>
                <div
                  className="blog-card blog-card-four wow fadeInUp"
                  data-wow-delay="100ms"
                  data-wow-duration="1500ms"
                >
                  <a href="#" className="blog-card__image">
                    <img
                      src={HajjPackageDetails.bannerLocation ? HajjPackageDetails.bannerLocation : `${process.env.PUBLIC_URL}/assets/img/hajjDefault.jpg`}
                      alt="Alhind Hajj Umrah"  style={{objectFit: 'fill', height: 'auto'}}
                    />

                  </a>
                  <div className=" blog-card-four__content">
                    {Number(HajjPackageDetails.package_Days) > 0 && <ul className="list-unstyled blog-card-four__meta">
                      <li>
                        <a href="#">
                          <span className="icon-user" />
                          {HajjPackageDetails.package_Days - 1} Nights
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span className="icon-comment" />{HajjPackageDetails.package_Days} Days
                        </a>
                      </li>
                    </ul>}

                    {HajjPackageDetails.overview && <div>
                      <h3 className="blog-card__title">
                        Overview
                      </h3>
                      <p className="blog-card-four__text blog-card-four__text--one">
                        {HajjPackageDetails.overview}
                      </p>
                    </div>}
                    <div className="row mb-3">
                      {HajjPackageDetails.packageDepartureDate && <div className="col-lg-6 col-md-6">
                        <h6 className="no-margin">Departure Date : {HajjPackageDetails.packageDepartureDate}</h6>
                      </div>}
                      {HajjPackageDetails.departurePlace && <div className="col-lg-6 col-md-6">
                        <h6 className="no-margin">Departure Airport : {HajjPackageDetails.departurePlace}</h6>
                      </div>}
                    </div>



                    <div className="blog-details__inner umrah-it">
                      {HajjPackageDetails.dayList.length > 0 && <h3 className="blog-card__title">
                        Package Itinerary
                      </h3>}


                      <Accordion defaultActiveKey={['0']} alwaysOpen>
                        {HajjPackageDetails.dayList.length > 0 && HajjPackageDetails.dayList.map((itemDay, index) => (
                          <Accordion.Item eventKey={index}>
                            <Accordion.Header>{itemDay.day_Name} - {itemDay.heading_Name}</Accordion.Header>
                            <Accordion.Body>
                              {itemDay.description}
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </Accordion>

                      {(HajjPackageDetails.inclusion || HajjPackageDetails.exclusion) && <div>
                        <h3 className="blog-card__title">
                          Package Inclusions & Exclusions
                        </h3>
                        <div className="sidebar__single1">
                          <div className="row">
                            {HajjPackageDetails.inclusion && <div className="col-lg-6 col-md-6">
                              <h4 className="blog-card__title">
                                Inclusions
                              </h4>
                              <ul>
                                {HajjPackageDetails.inclusion.split(',').map((item, index) => (
                                  <li key={index}>{item.trim()}</li>
                                ))}
                              </ul>
                            </div>}
                            {HajjPackageDetails.exclusion && <div className="col-lg-6 col-md-6">
                              <h4 className="blog-card__title">
                                Exclusions
                              </h4>
                              <ul>
                                {HajjPackageDetails.exclusion.split(',').map((item, index) => (
                                  <li key={index}>{item.trim()}</li>
                                ))}
                              </ul>
                            </div>}
                          </div>
                        </div>
                      </div>}


                      {HajjPackageDetails.cancellationpolicy && <div className="blog-details__inner__content mb-15">
                        <h3 className="blog-card__title">
                          Cancellation Policy
                        </h3>
                        <ul className="blog-details__inner__list">
                          {HajjPackageDetails.cancellationpolicy.split(',').map((item, index) => (
                            <li key={index}>{item.trim()}</li>
                          ))}
                        </ul>
                      </div>}
                      {HajjPackageDetails.termsandCondition && <div className="blog-details__inner__content">
                        <h3 className="blog-card__title">
                          Terms & Conditions
                        </h3>
                        <ul className="blog-details__inner__list">
                          {HajjPackageDetails.termsandCondition.split(',').map((item, index) => (
                            <li key={index}>{item.trim()}</li>
                          ))}
                        </ul>
                      </div>}

                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-lg-4">
              <div className="sidebar-enquiry">
                <aside className="widget-area">
                  <div className="sidebar__form sidebar__single">
                    {(Number(HajjPackageDetails.packageCost) > 0) && <div className="package_price_block d-flex flex-row align-items-center">
                      <h4>&#x20b9; {HajjPackageDetails.packageCost}</h4><span>/ Per Person</span>
                    </div>}

                    <div className="sidebar__search">
                      <input type="text" name="CustomerName" value={CustomerName} placeholder="Full Name*" onChange={this.handleChange} />

                      <input type="text" name="CustomerEmail" value={CustomerEmail} placeholder="Email ID*" onChange={this.handleChange} />
                      {isSubmitted && errors.CustomerEmail && <span className="error">{errors.CustomerEmail}</span>}

                      <input type="text" name="CustomerMobile" value={CustomerMobile} placeholder="Mobile No.*" onChange={this.handleChange} />
                      {isSubmitted && errors.CustomerMobile && <span className="error">{errors.CustomerMobile}</span>}

                      <button className="btn btn-primary w-100" onClick={this.handleSubmit}>SUBMIT</button>
                      {isSubmitted && !isFormValid && <span className="error">Fill All Fields</span>}
                    </div>
                  </div>
                  <div className="help-area">
                    <h4>Need Help?</h4>
                    <p>Our Destination expert will be happy to help you resolve your queries for this Package.</p>
                    <div className="d-flex flex-row align-items-center">
                      <div className="mr-10"><svg
                        height="30px"
                        width="30px"
                        version="1.1"
                        id="_x32_"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 512 512"
                        xmlSpace="preserve"
                        fill="#000000"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <style
                            type="text/css"
                            dangerouslySetInnerHTML={{ __html: " .st0{fill:#ed1c24;} " }}
                          />{" "}
                          <g>
                            {" "}
                            <path
                              className="st0"
                              d="M337.168,289.578c-5.129-5.133-13.457-5.133-18.598,0l-16.031,16.038c-4.68,4.68-12.122,5.149-17.352,1.102 c0,0-15.332-10.109-40.402-35.179c-25.07-25.07-35.175-40.414-35.175-40.414c-4.055-5.226-3.578-12.656,1.102-17.343l16.031-16.031 c5.141-5.134,5.141-13.462,0-18.594l-35.34-35.343c-5.133-5.133-13.453-5.133-18.594,0c-0.122,0.125-1.906,1.906-21.309,21.32 c-22.602,22.594,7.293,91.82,57.574,142.118c50.289,50.281,119.527,80.164,142.121,57.578 c19.394-19.406,21.184-21.203,21.305-21.32c5.141-5.133,5.141-13.461,0-18.586L337.168,289.578z"
                            />{" "}
                            <path
                              className="st0"
                              d="M256,0C114.614,0,0,114.617,0,256s114.614,256,256,256s256-114.617,256-256S397.386,0,256,0z M256,472 c-119.102,0-216-96.898-216-216S136.898,40,256,40s216,96.898,216,216S375.102,472,256,472z"
                            />{" "}
                          </g>{" "}
                        </g>
                      </svg></div>
                      <div><h4 className="no-margin">0495 2389900</h4></div>
                    </div>

                  </div>

                  {HajjPackageDetails.itineraryLocation && <div className="p-2 mb-3 mt-3">
                    <div className="p-share text-center">

                      <div className="text-center share-icon">

                        <a href={HajjPackageDetails.itineraryLocation} target="_blank" rel="noopener noreferrer" className="btn btn-download d-flex flex-row align-items-center justify-content-center">
                         <span className="mr-10">
                         <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M17 17H17.01M17.4 14H18C18.9319 14 19.3978 14 19.7654 14.1522C20.2554 14.3552 20.6448 14.7446 20.8478 15.2346C21 15.6022 21 16.0681 21 17C21 17.9319 21 18.3978 20.8478 18.7654C20.6448 19.2554 20.2554 19.6448 19.7654 19.8478C19.3978 20 18.9319 20 18 20H6C5.06812 20 4.60218 20 4.23463 19.8478C3.74458 19.6448 3.35523 19.2554 3.15224 18.7654C3 18.3978 3 17.9319 3 17C3 16.0681 3 15.6022 3.15224 15.2346C3.35523 14.7446 3.74458 14.3552 4.23463 14.1522C4.60218 14 5.06812 14 6 14H6.6M12 15V4M12 15L9 12M12 15L15 12" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                         </span>
                        <span>
                          Download Itinerary
                        </span>
                            
                         
                        </a>
                      </div>

                    </div>
                  </div>}

                </aside>
              </div>
            </div>



          </div>

        </div>
      </section>

    </div>
    ) : (
      <div>
        <div className="loader text-center">
          <div className="wait">
            <div className="text-center">
              <img src="../assets/img/loading-gif.gif" width={60} />
            </div>
           
          </div>
        </div>
      </div>
    )
  )
  }
}

// Create a functional component to wrap with useParams
const UmrahItineraryWithParams = () => {
  const { id, deptId } = useParams();
  return <UmrahItinerary id={id} deptId={deptId}/>;
};

export default UmrahItineraryWithParams;